<template>
  <div class="me-wrapper_main">
    <div class="me-face">
      <van-row>
        <van-col span="7">
          <div class="_face">
            <img src="../status/img/me_face.png" />
          </div>
        </van-col>
        <van-col span="16">
          <div class="_name">{{userInfo.nick_name}}</div>
          <div class="_points">
            <span>积点 |  {{userInfo.integral}}</span>
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="_img me-line">
      <img src="../status/img/me_line.png" />
    </div>
    <div class="_role_btn" @click="show_role">
      <img src="http://pic.eaworks.cn/scbank/uploads/202333/5532686.png" />
    </div>
    <div class="me-banner">
      <ul>
        <li class="_img" v-for="item of categoryList" @click="jumpClick(item)">
          <img :src="item.icon" />
        </li>
      </ul>
    </div>
    <div class="coming-soon" v-if="comingSoon" >
      <div class="_content">
        <div class="_window"><img :src="showImage" /></div>
        <div class="_closed" @click="comingSoon = false"><img src="../status/img/icon_closed.png" /></div>
      </div>
    </div>
    <Footer :step="2" />
  </div>
</template>

<script>
import Footer from './components/footer'
import Cookies from "js-cookie";

export default {
  components: {Footer},
  data() {
    return {
      categoryList:[ ],
      userInfo:{},
      comingSoon:false,
      showImage:""
    };
  },

  created() {
  },

  mounted() {
    this.getMyInfo();
     this.getCategoryList();
  },

  methods: {
    jumpClick(item){
      if(item.sub_cnt==0){
        this.$router.push({path:'/category',query:{id:item.id}})
      }else{
        this.$router.push({path:'/subCategory',query:{id:item.id}})
      }
    },
    async getCategoryList() {
      // 获取分类列表
      let {data,code} = await this.$ajax.gateway("/api/api_get_category_list", {
        "METHOD": "post"
      });
      this.categoryList = data;
    },
    async show_role(){
      // 获取资源图片地址
      let {data,code} = await this.$ajax.gateway("/api/api_get_resource_by_id", {
        "METHOD": "get",
        "id": 'd2106560-d82b-4fb1-baa7-f674d10ddc33'
      });
      if(code==200){
        this.showImage = data.pic_url;
        this.comingSoon=true;
      }else{
        alert("网络错误")
      }
    },
    async getMyInfo() {
      // 获取分类列表
      let {data,code } = await this.$ajax.gateway("/api/api_get_my_info", {
        ERROR_BACK:true,
        "METHOD": "get"
      });
      console.info("code=========>",code);
      if(code!=200){
        Cookies.remove('admin_hdbank_test_token');
        this.$router.push("/login")
      }else{
        this.userInfo=data;
      }
    },
    async getResoueceById(id){

    },

  }
};
</script>

<style lang="less" scoped>
  @import "../status/css/page.less";

  .me-face {
    padding: 2.4rem 0 1.4rem;
    ._face {
      text-align: right;
      img {
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 100%;
        border: 3px solid #c3ab81;
      }
    }
    ._name {
      color: #c8b489;
      padding-top: 0.2rem;
      font-size: 0.45rem;
      padding-left: 0.4rem;
    }
    ._points {
      padding-top: 0.15rem;
      padding-left: 0.2rem;
      span {
        display: inline-block;
        line-height: 0.6rem;
        padding: 0 0.3rem;
        background: #c8b489;
        color: #af1c1f;
        border-radius: 0.3rem;
        font-size: 0.3rem;
      }
    }
  }
  .me-line {
    margin-bottom: 0.6rem;
  }
  ._role_btn{
    position: fixed;
    right: 0;
    top: 2rem;
    z-index: 999;
    img{
      width: 1rem;
    }
  }

  .me-banner {
    padding: 0 0.8rem;
    ul li {
      margin-bottom: 0.6rem;
    }
  }
  .coming-soon {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.45);
    ._content {
      width: 100%;
      position: absolute;
      top: 2rem;
      left: 0;
      ._window {
        padding: 0 0.75rem;
        img {
          display: block;
          width: 100%;
        }
      }
      ._closed {
        padding: 0.4rem 0;
        text-align: center;
        img {
          width: 1.2rem;
        }
      }
    }
  }
</style>
